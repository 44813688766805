var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{attrs:{"variant":"danger","show":_vm.productData === undefined}},[_c('h4',{staticClass:"alert-heading"},[_vm._v("Error fetching user data")]),_c('div',{staticClass:"alert-body"},[_vm._v(" No products found with this user id. Check "),_c('b-link',{staticClass:"alert-link",attrs:{"to":{ name: 'apps-users-list' }}},[_vm._v(" products List ")]),_vm._v(" for other prducts. ")],1)]),(_vm.productData)?[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('product-info-card',{attrs:{"product":_vm.productData}})],1),_c('b-col',[_c('b-card',[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('h3',{staticClass:"mb-2"},[_vm._v("Información del producto")]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[(_vm.step === 1)?_c('validation-provider',{attrs:{"name":"Precio","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Precio","label-for":"precio"}},[_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_vm._v(" $ ")]),_c('b-form-input',{attrs:{"id":"precio","state":_vm.getValidationState(validationContext),"trim":"","placeholder":"0.00"},model:{value:(_vm.productFormData.unit_price),callback:function ($$v) {_vm.$set(_vm.productFormData, "unit_price", $$v)},expression:"productFormData.unit_price"}})],1),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}):_vm._e(),(_vm.step === 1)?_c('validation-provider',{attrs:{"name":"Cost","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Costo","label-for":"cost"}},[_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_vm._v(" $ ")]),_c('b-form-input',{attrs:{"id":"cost","state":_vm.getValidationState(validationContext),"trim":"","placeholder":"0.00"},model:{value:(_vm.productFormData.unit_cost),callback:function ($$v) {_vm.$set(_vm.productFormData, "unit_cost", $$v)},expression:"productFormData.unit_cost"}})],1),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}):_vm._e(),(_vm.step === 1)?_c('div',[_c('p',{staticClass:"mt-1 visibility"},[_vm._v("Visibilidad del producto")]),_c('b-button-group',{attrs:{"size":"sm"}},[_c('b-button',{attrs:{"variant":!_vm.productFormData.is_private
                        ? 'success'
                        : 'outline-secondary'},on:{"click":function($event){_vm.productFormData.is_private = false}}},[_vm._v(" Publico ")]),_c('b-button',{attrs:{"variant":_vm.productFormData.is_private
                        ? 'danger'
                        : 'outline-secondary'},on:{"click":function($event){_vm.productFormData.is_private = true}}},[_vm._v(" Privado ")])],1)],1):_vm._e(),(_vm.step === 2)?_c('div',{staticClass:"mt-2"},[_c('b-form-group',{attrs:{"label":"¿El producto o servicio tiene variantes que deseas combinar?","size":"lg"}},[_c('b-button-group',{staticClass:"d-flex",attrs:{"id":"has-variants"},model:{value:(_vm.selectedOptionForVariant),callback:function ($$v) {_vm.selectedOptionForVariant=$$v},expression:"selectedOptionForVariant"}},[_c('b-button',{attrs:{"variant":_vm.selectedOptionForVariant
                          ? 'success'
                          : 'outline-success'},on:{"click":function($event){return _vm.handleVariantButton(true)}}},[_vm._v("Si")]),_c('b-button',{attrs:{"variant":_vm.selectedOptionForVariant === false
                          ? 'danger'
                          : 'outline-danger'},on:{"click":function($event){return _vm.handleVariantButton(false)}}},[_vm._v("No")])],1)],1),(_vm.has_variants === '1')?_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Variantes"}},[(_vm.availableVariantOptions.length === 0)?_c('small',{staticClass:"text-danger pb-50"},[_vm._v(" *No hay variantes disponibles ")]):_vm._e(),_c('b-form-tags',{attrs:{"size":"lg","add-on-change":"","no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var tags = ref.tags;
                      var inputAttrs = ref.inputAttrs;
                      var inputHandlers = ref.inputHandlers;
                      var disabled = ref.disabled;
                      var removeTag = ref.removeTag;
return [(tags.length > 0)?_c('ul',{staticClass:"list-inline d-inline-block mb-1"},_vm._l((tags),function(tag){return _c('li',{key:tag,staticClass:"list-inline-item"},[_c('b-form-tag',{staticClass:"my-50 mr-25",attrs:{"title":tag,"disabled":disabled,"variant":"primary"},on:{"remove":function($event){removeTag(tag), _vm.handleRemoveTag()}}},[_vm._v(" "+_vm._s(_vm.getVariantTagNameById(tag))+" ")])],1)}),0):_vm._e(),_c('b-form-select',_vm._g(_vm._b({attrs:{"disabled":disabled || _vm.availableVariants.length === 0,"options":_vm.availableVariants,"text-field":"name","value-field":"id"},on:{"change":_vm.resetVariantOptionTags},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('option',{attrs:{"disabled":"","value":""}},[_vm._v(" Selecciona las variantes que deseas combinar... ")])]},proxy:true}],null,true)},'b-form-select',inputAttrs,false),inputHandlers))]}}],null,true),model:{value:(_vm.variants_ids),callback:function ($$v) {_vm.variants_ids=$$v},expression:"variants_ids"}})],1):_vm._e()],1):_vm._e(),(
                  _vm.has_variants === '1' &&
                  _vm.variants_ids.length >= 1 &&
                  _vm.step === 2
                )?_c('div',{staticClass:"d-flex mt-2"},[_c('b-form-group',{attrs:{"label":"Opciones de variantes"}},[_c('b-form-tags',{attrs:{"size":"lg","add-on-change":"","no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var tags = ref.tags;
                      var inputAttrs = ref.inputAttrs;
                      var inputHandlers = ref.inputHandlers;
                      var disabled = ref.disabled;
                      var removeTag = ref.removeTag;
return [(tags.length > 0)?_c('ul',{staticClass:"list-inline d-inline-block mb-1"},_vm._l((tags),function(tag){return _c('li',{key:tag,staticClass:"list-inline-item"},[_c('b-form-tag',{staticClass:"my-50 mr-25",attrs:{"title":tag,"disabled":disabled,"variant":"primary"},on:{"remove":function($event){return removeTag(tag)}}},[_vm._v(" "+_vm._s(_vm.getVariantOptionTagNameById(tag))+" ")])],1)}),0):_vm._e(),_c('b-form-select',_vm._g(_vm._b({attrs:{"disabled":disabled || _vm.availableVariantOptions.length === 0,"options":_vm.availableVariantOptions,"text-field":"combination_name","value-field":"ids"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('option',{attrs:{"disabled":"","value":""}},[_vm._v(" Selecciona las opciones deseadas... ")])]},proxy:true}],null,true)},'b-form-select',inputAttrs,false),inputHandlers))]}}],null,true),model:{value:(_vm.variant_option_ids),callback:function ($$v) {_vm.variant_option_ids=$$v},expression:"variant_option_ids"}})],1)],1):_vm._e(),(_vm.variant_option_ids.length > 0 && _vm.step === 3)?_c('div',{staticClass:"mt-2"},[_c('b-form-group',{attrs:{"label":"¿Deseas manejar inventario para las variantes?","label-size":"lg"}},[_c('b-button-group',{staticClass:"d-flex",attrs:{"id":"has-inventory"},model:{value:(_vm.selectedOptionForInventory),callback:function ($$v) {_vm.selectedOptionForInventory=$$v},expression:"selectedOptionForInventory"}},[_c('b-button',{attrs:{"variant":_vm.selectedOptionForInventory
                          ? 'success'
                          : 'outline-success'},on:{"click":function($event){return _vm.handleInventoryButton(true)}}},[_vm._v("Si")]),_c('b-button',{attrs:{"variant":_vm.selectedOptionForInventory === false
                          ? 'danger'
                          : 'outline-danger'},on:{"click":function($event){return _vm.handleInventoryButton(false)}}},[_vm._v("No")])],1)],1),(_vm.has_inventory === '1')?_c('div',{staticClass:"d-flex mt-2"},[_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0 font-"},[_vm._v(" Ingresa el inventario para cada una de las opciones de variantes ")])])],1),_vm._l((_vm.variant_option_ids),function(option,index){return _c('div',{key:index},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-left",attrs:{"size":"3","cols":"8"}},[_c('h6',[_c('b-badge',{staticClass:"mt-1",attrs:{"pill":"","variant":'light-info'}},[_vm._v(" "+_vm._s(_vm.getVariantOptionTagNameById(option))+" ")])],1)]),_c('b-col',{attrs:{"cols":"4"}},[_c('div',[_c('b-form-input',{attrs:{"id":'variant-option-info-' + index,"state":true,"trim":"","placeholder":"15","size":"lg","type":"number"},on:{"input":function($event){return _vm.updateVariantOptionInventory(option, $event)}},model:{value:(_vm.variant_option_inventory(option).units),callback:function ($$v) {_vm.$set(_vm.variant_option_inventory(option), "units", $$v)},expression:"variant_option_inventory(option).units"}})],1)])],1)],1)})],2)],1):_vm._e()],1):_vm._e(),(_vm.step === 4)?_c('b-row',{staticClass:"d-flex mt-2"},[_c('b-col',[_c('b-form-group',{attrs:{"label":"¿Deseas manejar inventario para este producto o servicio?","label-for":"has-inventory","label-size":"lg"}},[_c('b-button-group',{staticClass:"d-flex align-items-center",attrs:{"id":"has-inventory"},model:{value:(_vm.selectedOptionForInventory),callback:function ($$v) {_vm.selectedOptionForInventory=$$v},expression:"selectedOptionForInventory"}},[_c('b-button',{attrs:{"variant":_vm.selectedOptionForInventory
                            ? 'success'
                            : 'outline-success'},on:{"click":function($event){return _vm.handleInventoryButton(true)}}},[_vm._v("Si")]),_c('b-button',{attrs:{"variant":_vm.selectedOptionForInventory === false
                            ? 'danger'
                            : 'outline-danger'},on:{"click":function($event){return _vm.handleInventoryButton(false)}}},[_vm._v("No")])],1)],1)],1)],1):_vm._e(),(_vm.step === 4 && _vm.has_inventory === '1')?_c('b-row',{staticClass:"d-flex mt-2"},[_c('b-col',[_c('b-form-group',{attrs:{"label":"Ingresa el inventario para el producto","label-for":"unique-inventory"}},[_c('b-form-input',{attrs:{"id":"unique-inventory","autofocus":"","state":true,"trim":"","placeholder":"0","type":"number"},model:{value:(_vm.units),callback:function ($$v) {_vm.units=$$v},expression:"units"}})],1)],1)],1):_vm._e(),(_vm.step === 5)?_c('b-row',[_c('b-col',{attrs:{"size":"12"}},[_c('b-form-group',{attrs:{"label":"¿Deseas añadir una descripción?","label-for":"has-description","label-size":"lg"}},[_c('b-button-group',{staticClass:"d-flex align-items-center",attrs:{"id":"has-description"},model:{value:(_vm.selectedOptionForDescription),callback:function ($$v) {_vm.selectedOptionForDescription=$$v},expression:"selectedOptionForDescription"}},[_c('b-button',{attrs:{"variant":_vm.selectedOptionForDescription
                            ? 'success'
                            : 'outline-success'},on:{"click":function($event){return _vm.handleDescriptionButton(true)}}},[_vm._v("Si")]),_c('b-button',{attrs:{"variant":_vm.selectedOptionForDescription === false
                            ? 'danger'
                            : 'outline-danger'},on:{"click":function($event){return _vm.handleDescriptionButton(false)}}},[_vm._v("No")])],1)],1)],1)],1):_vm._e(),(_vm.has_description === '1' && _vm.step === 5)?_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"size":"12"}},[_c('validation-provider',{attrs:{"name":"ProductDescription","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
                            var valid = ref.valid;
return [_c('b-form-group',{attrs:{"label":"Escribe una descripción detallada...","label-for":"product_description"}},[_c('b-form-input',{attrs:{"id":"product_description","trim":"","placeholder":"Este producto es para...","type":"text","state":errors.length > 0 ? false : valid ? true : null},model:{value:(_vm.product_description),callback:function ($$v) {_vm.product_description=$$v},expression:"product_description"}})],1)]}}],null,true)})],1)],1):_vm._e(),(_vm.step === 6)?_c('b-row',[_c('b-col',{attrs:{"size":"12"}},[_c('b-form-group',{attrs:{"label":"¿Facturas las ventas de este producto?","label-for":"has-tax","label-size":"lg"}},[_c('b-button-group',{staticClass:"d-flex align-items-center",attrs:{"id":"has-tax"},model:{value:(_vm.selectedOptionForHasTax),callback:function ($$v) {_vm.selectedOptionForHasTax=$$v},expression:"selectedOptionForHasTax"}},[_c('b-button',{attrs:{"variant":_vm.selectedOptionForHasTax
                            ? 'success'
                            : 'outline-success'},on:{"click":function($event){return _vm.handleHasTaxButton(true)}}},[_vm._v("Si")]),_c('b-button',{attrs:{"variant":_vm.selectedOptionForHasTax === false
                            ? 'danger'
                            : 'outline-danger'},on:{"click":function($event){return _vm.handleHasTaxButton(false)}}},[_vm._v("No")])],1)],1)],1)],1):_vm._e(),(_vm.productData.product_type === 'product' && _vm.step === 7)?_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Unidad de medida","label-for":"unit-type"}},[_c('validation-provider',{attrs:{"name":"Codigo de unidad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-select',{staticClass:"h-10",attrs:{"options":_vm.unitCodeOptions,"reduce":function (val) { return val.value; },"label":"name"},model:{value:(_vm.unitCode),callback:function ($$v) {_vm.unitCode=$$v},expression:"unitCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1):_vm._e(),_c('div',{staticClass:"d-flex mt-2"},[(_vm.step > 1)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"outline-secondary"},on:{"click":function($event){_vm.step === 4 && _vm.variant_option_ids.length > 0
                      ? _vm.step--
                      : _vm.step !== 4
                      ? _vm.step--
                      : (_vm.step -= 2)}}},[_vm._v(" Regresar ")]):_vm._e(),(_vm.step === 1)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"button","variant":"outline-secondary","to":{
                    name: 'store-products',
                    params: { id: _vm.$route.params.id },
                  }}},[_vm._v(" Cancelar ")]):_vm._e(),(_vm.step === 2)?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":function($event){_vm.variant_option_ids.length > 0 && _vm.selectedOptionForVariant
                      ? _vm.step++
                      : (_vm.step += 2)}}},[_vm._v(" Continuar ")]):_vm._e(),(_vm.step === 3)?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":function($event){_vm.step += 2}}},[_vm._v(" Continuar ")]):_vm._e(),(_vm.step === 1)?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","disabled":isNaN(parseFloat(_vm.productFormData.unit_cost)) ||
                    isNaN(parseFloat(_vm.productFormData.unit_price))},on:{"click":function($event){_vm.step++}}},[_vm._v(" Continuar ")]):_vm._e(),(
                    _vm.step > 2 &&
                    _vm.step !== 3 &&
                    _vm.step !== 7 &&
                    _vm.productData.product_type === 'product'
                  )?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":function($event){_vm.step++}}},[_vm._v(" Continuar ")]):_vm._e(),(
                    _vm.step > 2 &&
                    _vm.step !== 6 &&
                    _vm.productData.product_type !== 'product'
                  )?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":function($event){_vm.step++}}},[_vm._v(" Continuar ")]):_vm._e(),(_vm.step === 6 && _vm.productData.product_type !== 'product')?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Agregar ")]):_vm._e(),(_vm.step === 7 && _vm.productData.product_type === 'product')?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Agregar ")]):_vm._e()],1)],1)]}}],null,false,2672986257)})],1)],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }