<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="productData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No products found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          products List
        </b-link>
        for other prducts.
      </div>
    </b-alert>

    <template v-if="productData">
      <!-- First Row -->
      <b-row class="match-height">
        <b-col cols="12" md="6">
          <product-info-card :product="productData" />
        </b-col>
        <b-col>
          <b-card>
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <h3 class="mb-2">Información del producto</h3>
              <!-- Form -->
              <b-form
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
              >
                <!-- PRICE -->
                <validation-provider
                  #default="validationContext"
                  name="Precio"
                  rules="required|numeric"
                  v-if="step === 1"
                >
                  <b-form-group label="Precio" label-for="precio">
                    <b-input-group>
                      <b-input-group-prepend is-text> $ </b-input-group-prepend>
                      <b-form-input
                        id="precio"
                        v-model="productFormData.unit_price"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="0.00"
                      />
                    </b-input-group>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <!-- COST -->
                <validation-provider
                  #default="validationContext"
                  name="Cost"
                  rules="required|numeric"
                  v-if="step === 1"
                >
                  <b-form-group label="Costo" label-for="cost">
                    <b-input-group>
                      <b-input-group-prepend is-text> $ </b-input-group-prepend>
                      <b-form-input
                        id="cost"
                        v-model="productFormData.unit_cost"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="0.00"
                      />
                    </b-input-group>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <!-- PRIVACY -->
                <div v-if="step === 1">
                  <p class="mt-1 visibility">Visibilidad del producto</p>
                  <b-button-group size="sm">
                    <b-button
                      :variant="
                        !productFormData.is_private
                          ? 'success'
                          : 'outline-secondary'
                      "
                      @click="productFormData.is_private = false"
                    >
                      Publico
                    </b-button>
                    <b-button
                      :variant="
                        productFormData.is_private
                          ? 'danger'
                          : 'outline-secondary'
                      "
                      @click="productFormData.is_private = true"
                    >
                      Privado
                    </b-button>
                  </b-button-group>
                </div>

                <div class="mt-2" v-if="step === 2">
                  <b-form-group
                    label="¿El producto o servicio tiene variantes que deseas combinar?"
                    size="lg"
                  >
                    <b-button-group
                      id="has-variants"
                      v-model="selectedOptionForVariant"
                      class="d-flex"
                    >
                      <b-button
                        :variant="
                          selectedOptionForVariant
                            ? 'success'
                            : 'outline-success'
                        "
                        @click="handleVariantButton(true)"
                        >Si</b-button
                      >
                      <b-button
                        :variant="
                          selectedOptionForVariant === false
                            ? 'danger'
                            : 'outline-danger'
                        "
                        @click="handleVariantButton(false)"
                        >No</b-button
                      >
                    </b-button-group>
                  </b-form-group>

                  <!-- Variants selection -->
                  <b-form-group
                    v-if="has_variants === '1'"
                    label="Variantes"
                    class="mt-2"
                  >
                    <small
                      v-if="availableVariantOptions.length === 0"
                      class="text-danger pb-50"
                    >
                      *No hay variantes disponibles
                    </small>
                    <b-form-tags
                      v-model="variants_ids"
                      size="lg"
                      add-on-change
                      no-outer-focus
                    >
                      <template
                        v-slot="{
                          tags,
                          inputAttrs,
                          inputHandlers,
                          disabled,
                          removeTag,
                        }"
                      >
                        <ul
                          v-if="tags.length > 0"
                          class="list-inline d-inline-block mb-1"
                        >
                          <li
                            v-for="tag in tags"
                            :key="tag"
                            class="list-inline-item"
                          >
                            <b-form-tag
                              :title="tag"
                              :disabled="disabled"
                              variant="primary"
                              class="my-50 mr-25"
                              @remove="removeTag(tag), handleRemoveTag()"
                            >
                              {{ getVariantTagNameById(tag) }}
                            </b-form-tag>
                          </li>
                        </ul>
                        <b-form-select
                          v-bind="inputAttrs"
                          :disabled="disabled || availableVariants.length === 0"
                          :options="availableVariants"
                          text-field="name"
                          value-field="id"
                          v-on="inputHandlers"
                          @change="resetVariantOptionTags"
                        >
                          <template v-slot:first>
                            <!-- This is required to prevent bugs with Safari -->
                            <option disabled value="">
                              Selecciona las variantes que deseas combinar...
                            </option>
                          </template>
                        </b-form-select>
                      </template>
                    </b-form-tags>
                  </b-form-group>
                </div>

                <!-- VARIANT OPTIONS COMBINATIONS-->
                <div
                  v-if="
                    has_variants === '1' &&
                    variants_ids.length >= 1 &&
                    step === 2
                  "
                  class="d-flex mt-2"
                >
                  <!-- Variants option selection -->
                  <b-form-group label="Opciones de variantes">
                    <b-form-tags
                      v-model="variant_option_ids"
                      size="lg"
                      add-on-change
                      no-outer-focus
                    >
                      <template
                        v-slot="{
                          tags,
                          inputAttrs,
                          inputHandlers,
                          disabled,
                          removeTag,
                        }"
                      >
                        <ul
                          v-if="tags.length > 0"
                          class="list-inline d-inline-block mb-1"
                        >
                          <li
                            v-for="tag in tags"
                            :key="tag"
                            class="list-inline-item"
                          >
                            <b-form-tag
                              :title="tag"
                              :disabled="disabled"
                              variant="primary"
                              class="my-50 mr-25"
                              @remove="removeTag(tag)"
                            >
                              {{ getVariantOptionTagNameById(tag) }}
                            </b-form-tag>
                          </li>
                        </ul>
                        <b-form-select
                          v-bind="inputAttrs"
                          :disabled="
                            disabled || availableVariantOptions.length === 0
                          "
                          :options="availableVariantOptions"
                          text-field="combination_name"
                          value-field="ids"
                          v-on="inputHandlers"
                        >
                          <template v-slot:first>
                            <!-- This is required to prevent bugs with Safari -->
                            <option disabled value="">
                              Selecciona las opciones deseadas...
                            </option>
                          </template>
                        </b-form-select>
                      </template>
                    </b-form-tags>
                  </b-form-group>
                </div>

                <div
                  class="mt-2"
                  v-if="variant_option_ids.length > 0 && step === 3"
                >
                  <b-form-group
                    label="¿Deseas manejar inventario para las variantes?"
                    label-size="lg"
                  >
                    <b-button-group
                      id="has-inventory"
                      v-model="selectedOptionForInventory"
                      class="d-flex"
                    >
                      <b-button
                        :variant="
                          selectedOptionForInventory
                            ? 'success'
                            : 'outline-success'
                        "
                        @click="handleInventoryButton(true)"
                        >Si</b-button
                      >
                      <b-button
                        :variant="
                          selectedOptionForInventory === false
                            ? 'danger'
                            : 'outline-danger'
                        "
                        @click="handleInventoryButton(false)"
                        >No</b-button
                      >
                    </b-button-group>
                  </b-form-group>
                  <div v-if="has_inventory === '1'" class="d-flex mt-2">
                    <b-col>
                      <b-row>
                        <b-col cols="12" class="mb-2">
                          <p class="mb-0 font-">
                            Ingresa el inventario para cada una de las opciones
                            de variantes
                          </p>
                        </b-col>
                      </b-row>
                      <div
                        v-for="(option, index) in variant_option_ids"
                        :key="index"
                      >
                        <b-row>
                          <b-col
                            size="3"
                            class="d-flex align-items-left"
                            cols="8"
                          >
                            <h6>
                              <b-badge
                                pill
                                :variant="'light-info'"
                                class="mt-1"
                              >
                                {{ getVariantOptionTagNameById(option) }}
                              </b-badge>
                            </h6>
                          </b-col>
                          <b-col cols="4">
                            <div>
                              <b-form-input
                                :id="'variant-option-info-' + index"
                                v-model="variant_option_inventory(option).units"
                                :state="true"
                                trim
                                placeholder="15"
                                size="lg"
                                type="number"
                                @input="
                                  updateVariantOptionInventory(option, $event)
                                "
                              />
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </div>
                </div>

                <b-row v-if="step === 4" class="d-flex mt-2">
                  <b-col>
                    <b-form-group
                      label="¿Deseas manejar inventario para este producto o servicio?"
                      label-for="has-inventory"
                      label-size="lg"
                    >
                      <b-button-group
                        id="has-inventory"
                        v-model="selectedOptionForInventory"
                        class="d-flex align-items-center"
                      >
                        <b-button
                          :variant="
                            selectedOptionForInventory
                              ? 'success'
                              : 'outline-success'
                          "
                          @click="handleInventoryButton(true)"
                          >Si</b-button
                        >
                        <b-button
                          :variant="
                            selectedOptionForInventory === false
                              ? 'danger'
                              : 'outline-danger'
                          "
                          @click="handleInventoryButton(false)"
                          >No</b-button
                        >
                      </b-button-group>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row
                  v-if="step === 4 && has_inventory === '1'"
                  class="d-flex mt-2"
                >
                  <b-col>
                    <b-form-group
                      label="Ingresa el inventario para el producto"
                      label-for="unique-inventory"
                    >
                      <b-form-input
                        id="unique-inventory"
                        v-model="units"
                        autofocus
                        :state="true"
                        trim
                        placeholder="0"
                        type="number"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row v-if="step === 5">
                  <b-col size="12">
                    <b-form-group
                      label="¿Deseas añadir una descripción?"
                      label-for="has-description"
                      label-size="lg"
                    >
                      <b-button-group
                        id="has-description"
                        v-model="selectedOptionForDescription"
                        class="d-flex align-items-center"
                      >
                        <b-button
                          :variant="
                            selectedOptionForDescription
                              ? 'success'
                              : 'outline-success'
                          "
                          @click="handleDescriptionButton(true)"
                          >Si</b-button
                        >
                        <b-button
                          :variant="
                            selectedOptionForDescription === false
                              ? 'danger'
                              : 'outline-danger'
                          "
                          @click="handleDescriptionButton(false)"
                          >No</b-button
                        >
                      </b-button-group>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row
                  v-if="has_description === '1' && step === 5"
                  class="mt-2"
                >
                  <b-col size="12">
                    <validation-provider
                      #default="{ errors, valid }"
                      name="ProductDescription"
                      rules="required"
                    >
                      <b-form-group
                        label="Escribe una descripción detallada..."
                        label-for="product_description"
                      >
                        <b-form-input
                          id="product_description"
                          v-model="product_description"
                          trim
                          placeholder="Este producto es para..."
                          type="text"
                          :state="
                            errors.length > 0 ? false : valid ? true : null
                          "
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-row v-if="step === 6">
                  <b-col size="12">
                    <b-form-group
                      label="¿Facturas las ventas de este producto?"
                      label-for="has-tax"
                      label-size="lg"
                    >
                      <b-button-group
                        id="has-tax"
                        v-model="selectedOptionForHasTax"
                        class="d-flex align-items-center"
                      >
                        <b-button
                          :variant="
                            selectedOptionForHasTax
                              ? 'success'
                              : 'outline-success'
                          "
                          @click="handleHasTaxButton(true)"
                          >Si</b-button
                        >
                        <b-button
                          :variant="
                            selectedOptionForHasTax === false
                              ? 'danger'
                              : 'outline-danger'
                          "
                          @click="handleHasTaxButton(false)"
                          >No</b-button
                        >
                      </b-button-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row
                  v-if="productData.product_type === 'product' && step === 7"
                >
                  <b-col sm="6">
                    <b-form-group
                      label="Unidad de medida"
                      label-for="unit-type"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Codigo de unidad"
                        rules="required"
                      >
                        <v-select
                          v-model="unitCode"
                          :options="unitCodeOptions"
                          :reduce="(val) => val.value"
                          label="name"
                          class="h-10"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-secondary"
                    class="mr-2"
                    @click="
                      step === 4 && variant_option_ids.length > 0
                        ? step--
                        : step !== 4
                        ? step--
                        : (step -= 2)
                    "
                    v-if="step > 1"
                  >
                    Regresar
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="button"
                    variant="outline-secondary"
                    :to="{
                      name: 'store-products',
                      params: { id: $route.params.id },
                    }"
                    v-if="step === 1"
                    class="mr-1"
                  >
                    Cancelar
                  </b-button>
                  <b-button
                    v-if="step === 2"
                    class="mr-2"
                    variant="primary"
                    @click="
                      variant_option_ids.length > 0 && selectedOptionForVariant
                        ? step++
                        : (step += 2)
                    "
                  >
                    Continuar
                  </b-button>
                  <b-button
                    v-if="step === 3"
                    class="mr-2"
                    variant="primary"
                    @click="step += 2"
                  >
                    Continuar
                  </b-button>
                  <b-button
                    v-if="step === 1"
                    class="mr-2"
                    variant="primary"
                    @click="step++"
                    :disabled="
                      isNaN(parseFloat(productFormData.unit_cost)) ||
                      isNaN(parseFloat(productFormData.unit_price))
                    "
                  >
                    Continuar
                  </b-button>
                  <b-button
                    v-if="
                      step > 2 &&
                      step !== 3 &&
                      step !== 7 &&
                      productData.product_type === 'product'
                    "
                    class="mr-2"
                    variant="primary"
                    @click="step++"
                  >
                    Continuar
                  </b-button>
                  <b-button
                    v-if="
                      step > 2 &&
                      step !== 6 &&
                      productData.product_type !== 'product'
                    "
                    class="mr-2"
                    variant="primary"
                    @click="step++"
                  >
                    Continuar
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                    v-if="step === 6 && productData.product_type !== 'product'"
                  >
                    Agregar
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                    v-if="step === 7 && productData.product_type === 'product'"
                  >
                    Agregar
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from "@/store"
import router from "@/router"
import { ref } from "@vue/composition-api"
import ProductInfoCard from "@/views/products/product-view/ProductInfoCard.vue"
import formValidation from "@core/comp-functions/forms/form-validation"
import Ripple from "vue-ripple-directive"
import { required, alphaNum } from "@validations"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { mapActions, mapGetters, mapMutations } from "vuex"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import vSelect from "vue-select"
import messagesMixin from "@/@core/mixins/messagesMixin"

export default {
  components: {
    // Local Components
    ProductInfoCard,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  setup() {
    const productData = ref(null)
    store
      .dispatch("products/fetchProduct", router.currentRoute.params.product_id)
      .then((response) => {
        productData.value = response
      })
      .catch((error) => {
        if (error.response.status === 404) {
          productData.value = undefined
        }
      })

    const blankProductData = {
      unit_cost: 0,
      unit_price: 0,
      store_id: router.currentRoute.params.id,
      product_id: router.currentRoute.params.product_id,
      has_inventory: false,
      inventory: 0,
      is_private: false,
    }

    const productFormData = ref(JSON.parse(JSON.stringify(blankProductData)))
    const resetProductData = () => {
      productFormData.value = JSON.parse(JSON.stringify(blankProductData))
    }

    const { getValidationState } = formValidation(resetProductData)

    return {
      getValidationState,
      productData,
      productFormData,
    }
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      selectedOptionForVariant: false,
      has_variants: "0",
      has_inventory: "0",
      has_description: "0",
      has_tax_response: "0",
      variants_ids: [],
      step: 1,
      units: 0,
      variant_option_inventories: {},
      variant_option_ids: [],
      variant_options_combinations: [],
      entriesPerPage: 1000000,
      selectedOptionForInventory: false,
      selectedOptionForDescription: false,
      product_description: "",
      selectedOptionForHasTax: false,
      unitCode: null,
      unitCodeOptions: [
        {
          name: "Por pieza",
          value: "H87",
        },
        {
          name: "Por kilogramo",
          value: "KGM",
        },
        {
          name: "Por litro",
          value: "LTR",
        },
        {
          name: "Por gramo",
          value: "GRM",
        },
        {
          name: "Por miligramo",
          value: "MGM",
        },
        {
          name: "Por mililitro",
          value: "MLT",
        },
        {
          name: "Por tonelada de carga",
          value: "A75",
        },
      ],
    }
  },
  computed: {
    ...mapGetters("variants", ["variants", "selected_variants"]),

    availableVariants() {
      // eslint-disable-next-line
      return this.variants.filter((opt) => {
        return this.variants_ids.indexOf(opt.id) === -1
      })
    },

    availableVariantOptions() {
      return this.variant_options_combinations.filter((opt) => {
        return this.variant_option_ids.indexOf(opt.ids) === -1
      })
    },

    variant_option_inventory() {
      return (optionId) => {
        return this.variant_option_inventories[optionId] || { units: null }
      }
    },
  },
  beforeMount() {
    this.fetchVariants({
      by_active_status: "active",
      meta: {
        pagination: {
          per_page: this.entriesPerPage,
        },
      },
    })
  },
  mixins: [messagesMixin],
  watch: {
    variants_ids() {
      this.set_selected_variants([])
      const selectedVariants = this.variants.filter((variant) => {
        return this.variants_ids.indexOf(variant.id) !== -1
      })
      this.set_selected_variants(selectedVariants)
      this.setVariantOptions()
    },
  },

  methods: {
    ...mapActions("storeProducts", ["addStoreProduct"]),
    ...mapActions("variants", ["fetchVariants"]),
    ...mapMutations("variants", [
      "set_selected_variants",
      "delete_selected_variants",
    ]),

    setOptionNames() {
      let option_names = []
      this.selected_variants.forEach((variant) => {
        let options = []
        variant.variant_options_attributes.forEach((option) => {
          options.push({ id: option.id, option_name: option.option_name })
        })
        option_names.push(options)
      })
      return option_names
    },
    onSubmit() {
      if (!this.productFormData.has_inventory) {
        this.productData.inventory = 0
      }

      this.productFormData.unit_code =
        this.productData.product_type === "product" ? this.unitCode : "E48"

      const productData = this.setStoreProduct()

      const promises = productData.map((product) => {
        return this.addStoreProduct(product).catch((e) => {
          throw e
        })
      })

      Promise.all(promises)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Producto agregado",
              icon: "CheckIcon",
              variant: "success",
            },
          })
          this.$router.push({
            name: "store-products",
            params: { id: this.$route.params.id },
          })
        })
        .catch((e) => {
          let error = "Por favor, intenta de nuevo."

          if (Array.isArray(e.response.data.messages)) {
            error = e.response.data.messages[0]
          } else if (e.response.data.messages.hasOwnProperty("product")) {
            error = e.response.data.messages.product[0]
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "XCircleIcon",
              variant: "danger",
              text: error,
            },
          })
        })
    },
    handleHasTaxButton(value) {
      this.selectedOptionForHasTax = value
      this.has_tax_response = value ? "1" : "-1"
    },
    handleRemoveTag() {
      this.variant_option_ids = []
    },

    setVariantOptions() {
      function generateCombinations(
        arraysOfObjects,
        currentIndex = 0,
        currentCombination = "",
        currentIds = "",
        result = []
      ) {
        if (currentIndex === arraysOfObjects.length) {
          result.push({ combination_name: currentCombination, ids: currentIds })
          return
        }

        arraysOfObjects[currentIndex].forEach((option) => {
          const updatedIds = currentIds
            ? `${currentIds};${option.id}`
            : option.id // Update ids string
          generateCombinations(
            arraysOfObjects,
            currentIndex + 1,
            currentCombination.length
              ? `${currentCombination} - ${option.option_name}`
              : option.option_name,
            updatedIds,
            result
          )
        })
      }

      const result = []
      const option_names = this.setOptionNames()
      generateCombinations(option_names, 0, "", [], result)
      this.variant_options_combinations = result
    },

    setStoreProduct() {
      const baseProduct = {
        ...this.productFormData,
        description: this.product_description,
        unit_code: this.unitCode || "E48",
        has_inventory: this.has_inventory === "1",
        has_tax: this.has_tax_response === "1",
        tax_type: this.has_tax_response === "1" ? "IVA" : "",
        price_includes_tax: this.has_tax_response === "1",
      }

      if (this.variant_option_ids.length === 0) {
        return [
          {
            ...baseProduct,
            inventory: this.units,
          },
        ]
      } else {
        return this.variant_option_ids.map((optionId) => {
          const inventory = this.variant_option_inventories[optionId]
          const store_product_variants_attributes = optionId
            .split(";")
            .filter(Boolean)
            .map((id) => ({
              variant_option_id: id,
            }))
          return {
            ...baseProduct,
            inventory: inventory ? this.units : 0,
            store_product_variants_attributes,
          }
        })
      }
    },

    handleVariantButton(value) {
      this.selectedOptionForVariant = value
      this.has_variants = value ? "1" : "-1"
    },
    updateVariantOptionInventory(optionId, value) {
      this.$set(this.variant_option_inventories, optionId, { units: value })
    },

    handleInventoryButton(value) {
      this.selectedOptionForInventory = value
      this.has_inventory = value ? "1" : "-1"
    },
    resetVariantOptionTags() {
      this.variant_option_ids = []
    },
    handleDescriptionButton(value) {
      this.selectedOptionForDescription = value
      this.has_description = value ? "1" : "-1"
    },
    getVariantTagNameById(id) {
      let name = ""
      this.variants.forEach((x) => {
        if (x.id === id) {
          name = x.name
        }
      })
      return name
    },
    getVariantOptionTagNameById(id) {
      let name = ""
      this.variant_options_combinations.forEach((x) => {
        if (x.ids === id) {
          name = x.combination_name
        }
      })
      return name
    },
  },
}
</script>

<style lang="scss" scoped>
.visibility {
  font-size: 0.9rem;
  font-weight: 500;
}
</style>
